//import Slideout from 'slideout';

import jQuery from "jquery";
import TweenMax from "TweenMax";
import ScrollMagic from "ScrollMagic";
import "animation.gsap";
import "debug.addIndicators";
import { Power1 } from "gsap";

export default {
  init() {

    //Set the background color if not the homepage.
    if (!jQuery('body').hasClass('home')) {
      jQuery('body').css('background-color', 'rgb(87, 87, 86)');
    }
    
      


    /*----------------------------------------------------*/
    /*------------- Parallax Scroll Effect ---------------*/
    /*----------------------------------------------------*/

    var controller = new ScrollMagic.Controller();

    //Create Parralax effect on scroll.
    var parralaxScrollOne = new ScrollMagic.Scene({
      triggerElement: "body",
      duration: 700,
      offset: 0
    })
      .setTween("#overlayOne", {
        y: 100,
        rotation: 0.01,
        ease: Sine.easeInOut
      })
      .addTo(controller);

    //Create Parralax effect on scroll.
    var parralaxScrollTwo = new ScrollMagic.Scene({
      triggerElement: "body",
      duration: 700,
      offset: 0
    })
      .setTween("#overlayTwo", {
        y: 200,
        rotation: 0.01,
        ease: Sine.easeInOut
      })
      .addTo(controller);

    /*----------------------------------------------------*/
    /*------------- Open and Close the Nav ---------------*/
    /*----------------------------------------------------*/

    var nav_open = document.getElementById("nav-open");
    var nav_close = document.getElementById("nav-close");

    nav_open.addEventListener("click", function() {
      if (this.classList.contains('active')) {
        TweenMax.to(".nav-drawer", 0.5, {
          x: 300,
          ease: Sine.easeInOut
        });
        TweenMax.to("#nav-open", 0.5, {
          x: 0,
          ease: Sine.easeInOut
        });
        this.classList.remove('active');
      } else {
        TweenMax.to(".nav-drawer", 0.5, {
          x: 0,
          ease: Sine.easeInOut
        });
        
        TweenMax.to("#nav-open", 0.5, {
          x: -300,
          ease: Sine.easeInOut
        });
        this.classList.add('active');
      }
    });

    nav_close.addEventListener("click", function() {
      TweenMax.to(".nav-drawer", 0.5, {
        x: 300,
        ease: Sine.easeInOut
      });
      TweenMax.to("#nav-open", 0.5, {
        x: 0,
        ease: Sine.easeInOut
      });
      nav_open.classList.remove('active');
    });
  },
  finalize() {
    
  },
};
