import TweenMax from "TweenMax";
import ScrollMagic from "ScrollMagic";
import "animation.gsap";
import "debug.addIndicators";
import { Power1 } from "gsap";

export default {
  init() {

    var width = jQuery(window).width()
    console.log(width);

    /*----------------------------------------------------*/
    /*--------------- Page Load Animation ----------------*/
    /*----------------------------------------------------*/    
    
    var tl = new TimelineMax({ delay: 0.5, repeat: 0 });
    var lt = new TimelineMax({ repeat: 0 });
    
    //Check viewport width before playing animation. Animation disabled on devices less than 1200px.
    if (width > 1200) {
      tl.from(".shapeLeft", 2, { x: -1500, ease: Power3.easeOut });
      tl.from(".shapeRight", 2, { x: 1500, ease: Power3.easeOut }, "-=2");
      tl.from(".shapeTop", 2, { y: -1500, ease: Power3.easeOut }, "-=1.8");
      tl.from(".shapeBottom", 2, { y: 1500, ease: Power3.easeOut }, "-=2");
      tl.from(
        ".header-icon-nav",
        1,
        { opacity: 0, ease: Power3.easeOut },
        "-=0.5"
      );
      
      tl.from(
        ".headline-container",
        1,
        { opacity: 0, ease: Power3.easeOut },
        "-=0.5"
      );
      tl.from(".nav-tab", 0.5, { opacity: 0, x: 40 }, "-=0.5");
      tl.from(".page-wrap", 0.5, { opacity: 0 }, "-=0.5");
      lt.from("body", 1, { opacity: 0, ease: Power3.easeOut });
      lt.to('body', 0.5, {backgroundColor: 'rgb(236, 96, 138)'});
    } else {
      
      //lt.to('body', 0, {backgroundColor: 'rgb(87, 87, 86)'});
      lt.to('body', 0.5, {backgroundColor: 'rgb(236, 96, 138)'});
      lt.from("body", 0, { opacity: 0 });
    }
    

    /*----------------------------------------------------*/
    /*----------- Set the body color on load -------------*/
    /*----------------------------------------------------*/

    //jQuery('body').css("background-color", 'rgb(236, 96, 138)')

    /*----------------------------------------------------*/
    /*------- Page Scroll Background Color Change --------*/
    /*----------------------------------------------------*/

    var controller = new ScrollMagic.Controller();

    //Transition colour of SVG section on scroll
    if (width > 1200) {
    var svgAnim = new ScrollMagic.Scene({
      triggerElement: "#content",
      duration: 400,
      offset: -500,
    })
      .setTween("#shapeBottom", {
        fill: "#575756"
      })
      .addTo(controller);

      var contentAnim = new ScrollMagic.Scene({
        triggerElement: "#content",
        duration: 400,
        offset: -500,
      })
        .setTween("body", {
          backgroundColor: "#575756"
        })
        .addTo(controller);
    } else { 
      var svgAnim = new ScrollMagic.Scene({
        triggerElement: "#content",
        duration: 400,
        offset: 0,
      }).setTween("#shapeBottom", {
          fill: "#575756"
        }).addTo(controller);

        var contentAnim = new ScrollMagic.Scene({
          triggerElement: "#content",
          duration: 400,
          offset: 0,
        })
          .setTween("body", {
            backgroundColor: "#575756"
          })
          .addTo(controller);
    
    }
    
  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
  }
};
